header {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);

  @media (max-width: 768.5px) {
    height: 100px;
  }

  .headerWrapper {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1500px) {
      padding: 0 30px;
    }

    .left,
    .right {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .left {
      justify-content: flex-start;
    }

    .right {
      justify-content: flex-end;
      gap: 10px;

      @media (max-width: 768.5px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #bf9b5780;
        padding: 5px 20px;
        border-radius: 30px;
        gap: 10px;
        text-decoration: none;
        color: inherit;

        @media (max-width: 768.5px) {
          width: 150px;
        }
      }

      img {
        max-width: 16px;
        max-height: 16px;
      }
    }
  }
}
