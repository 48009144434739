html {
  scroll-behavior: smooth;
}

html, body, main {
  min-height: 100%;
}

body {
  color: #bf9b57;
  background: linear-gradient(106.95deg, #34415e .57%, #0f1117 100%);
}

.instagramLogo, .instagramLogo path {
  fill: red;
  stroke: red;
}

* {
  box-sizing: border-box;
}

header {
  width: 100%;
  height: 75px;
  z-index: 2;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: #0003;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 768.5px) {
  header {
    height: 100px;
  }
}

header .headerWrapper {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 1500px) {
  header .headerWrapper {
    padding: 0 30px;
  }
}

header .headerWrapper .left, header .headerWrapper .right {
  height: 100%;
  flex: 1;
  align-items: center;
  display: flex;
}

header .headerWrapper .left {
  justify-content: flex-start;
}

header .headerWrapper .right {
  justify-content: flex-end;
  gap: 10px;
}

@media (max-width: 768.5px) {
  header .headerWrapper .right {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

header .headerWrapper .right a {
  color: inherit;
  border: 1px solid #bf9b5780;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 20px;
  text-decoration: none;
  display: flex;
}

@media (max-width: 768.5px) {
  header .headerWrapper .right a {
    width: 150px;
  }
}

header .headerWrapper .right img {
  max-width: 16px;
  max-height: 16px;
}

section {
  min-height: 100vh;
}

.s1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.s1 .brandCard {
  width: 800px;
  max-width: 90vw;
  height: max-content;
  transform: rotate3d(0);
  text-align: right;
  z-index: 1;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  transition: transform .3s ease-out, box-shadow .3s ease-out;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 5px #0009;
}

.s1 .brandCard img {
  max-width: 100%;
}

.s1 .brandCard:hover {
  transition-duration: .15s;
  box-shadow: 0 5px 20px 5px #0004;
}

.s1 .brandCard .glow {
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 5% -2%, #fff2, #0000000f);
  position: absolute;
  top: 0;
  left: 0;
}

.s1 .downArrow {
  height: 30px;
  width: 30px;
  margin-top: 50px;
}

.s2 {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px;
  display: flex;
  padding-top: 100px !important;
}

@media (max-width: 768.5px) {
  .s2 {
    min-height: 0;
    padding: 20px;
  }
}

.s2 h1, .s2 h2, .s2 p {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.s3 {
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

@media (max-width: 900px) {
  .s3 {
    min-height: 0;
    max-height: 700px !important;
  }
}

.s3 .carouselContainer {
  width: 800px;
  height: 500px;
  border-radius: 15px;
  overflow: hidden;
}

@media (max-width: 900px) {
  .s3 .carouselContainer {
    width: 88.8889vw;
    height: 55.5556vw;
  }
}

.s3 .carouselContainer #carouselExampleIndicators {
  width: 100%;
  height: 100%;
}

.s3 .carouselContainer #carouselExampleIndicators .carousel-inner {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.s3 .carouselContainer #carouselExampleIndicators .carousel-inner .carousel-item {
  width: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.s3 .carouselContainer #carouselExampleIndicators .carousel-inner .carousel-item img {
  height: auto;
  max-width: 100%;
  display: block;
}

footer {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 30px 30px 100px;
  display: flex;
}

footer .line {
  width: 80%;
  max-width: 600px;
  height: 1px;
  background-color: #bf9b57;
}

footer .bottom {
  text-align: center;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

/*# sourceMappingURL=index.2499bcc8.css.map */
