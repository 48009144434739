footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 30px 30px 100px 30px;
  text-align: center;

  .line {
    width: 80%;
    max-width: 600px;
    height: 1px;
    background-color: #bf9b57;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 400px;
    gap: 20px;
  }
}
