html {
  scroll-behavior: smooth;
}

html,
body,
main {
  min-height: 100%;
}

body {
  background: linear-gradient(106.95deg, #34415e 0.57%, #0f1117 100%);
  color: #bf9b57;
}

.instagramLogo {
  fill: red;
  stroke: red;

  path {
    fill: red;
    stroke: red;
  }
}
