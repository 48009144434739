section {
  min-height: 100vh;
}

.s1 {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .brandCard {
    width: 800px;
    max-width: 90vw;
    height: max-content;
    transition: 0.25s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    transition-duration: 300ms;
    transition-property: transform, box-shadow;
    transition-timing-function: ease-out;
    transform: rotate3d(0);
    text-align: right;
    box-shadow: 0 1px 5px #00000099;
    border-radius: 10px;
    z-index: 1;

    img {
      max-width: 100%;
    }

    &:hover {
      transition-duration: 150ms;
      box-shadow: 0 5px 20px 5px #00000044;
    }

    .glow {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      background-image: radial-gradient(circle at 5% -2%, #ffffff22, #0000000f);
    }
  }

  .downArrow {
    height: 30px;
    width: 30px;
    margin-top: 50px;
  }
}

.s2 {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding-top: 100px !important;

  @media (max-width: 768.5px) {
    min-height: 0px;
    padding: 20px;
  }

  h1,
  h2,
  p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.s3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  @media (max-width: 900px) {
    max-height: 700px !important;
    min-height: 0px;
  }

  .carouselContainer {
    width: 800px;
    height: 500px;
    border-radius: 15px;
    overflow: hidden;

    @media (max-width: 900px) {
      width: 88.8888888889vw;
      height: 55.5555555556vw;
    }

    #carouselExampleIndicators {
      width: 100%;
      height: 100%;

      .carousel-inner {
        width: 100%;
        height: 100%;
        display: flex;
        margin: 0 auto;

        .carousel-item {
          width: 100%;
          overflow: hidden;
          max-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            display: block;
            height: auto;
            max-width: 100%;
          }
        }
      }
    }
  }
}
